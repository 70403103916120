<template>
  <div class="project-setting" v-if="selectedProjectType != null">
    <div class="header">
      <div class="titleG">
        <div @click="handelCancel($event)" class="fleche">
          <font-awesome-icon class="" icon="arrow-circle-left" />
        </div>
        <div class="titre">
          <h3>
            Gestion des Types des projets : {{ selectedProjectType.name }}
          </h3>
        </div>
      </div>
    </div>
    <div class="blockMenu">
      <div
        @click="clickMenu('champ-personnalise')"
        class="menu-item"
        :class="{ 'menu-item-active': selectedMenu === 'champ-personnalise' }"
      >
        Gestion des Champs personnalisés
      </div>
      <div
        @click="clickMenu('sous-type')"
        class="menu-item"
        :class="{ 'menu-item-active': selectedMenu === 'sous-type' }"
      >
        Gestion des sous-types
      </div>
    </div>
    <hr class="lineMenu" />
    <div v-if="selectedMenu === 'champ-personnalise'" class="ml-5 pb-5">
      <div v-if="initLoading" class="init-loading three-dots-loading">
        Chargement en cours
      </div>
      <b-row>
        <form v-if="projectToUpdate" @submit.prevent="updateProject">
          <b-form-group label="Nom" label-for="Nom-input">
            <b-form-input
              id="Nom-input"
              v-model="projectToUpdate.name"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Champs personnalisés">
            <b-form-checkbox-group
              v-model="projectToUpdate.selected"
              :options="getCustomFieldProject"
              value-field="id"
              text-field="name"
              switches
              stacked
            ></b-form-checkbox-group>
          </b-form-group>
          <!--  Type_Projet Modal: messages -->
          <div class="message">
            <div v-if="getProjectsTypesLoading" class="three-dots-loading">
              Chargement en cours
            </div>
            <div v-if="getProjectsTypesError" class="error">
              <ul v-if="Array.isArray(getProjectsTypesError)">
                <li v-for="(e, index) in getProjectsTypesError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsTypesError }}</span>
            </div>
          </div>
          <!-- Update Type_Projet Modal: buttons -->
          <div class="form-actions">
            <b-button variant="success" type="submit">
              Enregistrer
            </b-button>
          </div>
        </form>
      </b-row>
    </div>
    <div v-if="selectedMenu === 'sous-type'">
      <div class="header-sub-type">
        <div class="ml-2"><h4>Sous-types</h4></div>
        <div class="button">
          <b-button
            class="addSousType"
            size="sm"
            variant="success"
            v-b-modal.addProjetsSousTypesModal
          >
            <font-awesome-icon class="" icon="plus" />
            Ajouter un sous-type
          </b-button>
        </div>
      </div>

      <div class="message">
        <div v-if="getProjectsTypesLoading" class="three-dots-loading">
          Chargement en cours
        </div>
        <div v-if="getProjectsTypesError" class="error">
          <ul v-if="Array.isArray(getProjectsTypesError)">
            <li v-for="(e, index) in getProjectsTypesError" :key="index">
              {{ e }}
            </li>
          </ul>
          <span v-else>{{ getProjectsTypesError }}</span>
        </div>
      </div>

      <div class="card-scene pb-4" ref="cardSceneTypeProject">
        <Container
          v-if="selectedProjectType.subType"
          :drop-placeholder="dropPlaceholderOptions"
          :lock-axis="'y'"
          style="cursor: move;"
          @drop="onColumnDrop"
          drag-handle-selector=".column-drag-container "
        >
          <Draggable
            v-for="sousType in selectedProjectType.subType"
            :key="sousType.id"
          >
            <div class="column-drag-container ">
              <div class="card-column-header">
                <div class="column-drag-handle">
                  ☰
                </div>
                <div class="input-title-sous-type">
                  <div class="body-drag">
                    <input
                      placeholder="Nom de sous type"
                      @change="updateNameSousType($event, sousType)"
                      title="Modifier le nom de sous type"
                      :value="sousType.name"
                    />
                    <div>
                      <span class="span-title ml-3">Catégorie</span>
                      <multiselect
                        class="multiselectM"
                        @input="handleCategorieChange($event, sousType)"
                        v-model="sousType.selectedCategorie"
                        :options="getListCategorieInTable"
                        placeholder="Rechercher"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :preserve-search="true"
                        :showLabels="false"
                        label="name"
                        track-by="name"
                        :multiple="true"
                      >
                        <span slot="noResult">Aucune option trouvée.</span>
                        <span slot="noOptions">Aucune option trouvée.</span>
                      </multiselect>
                    </div>
                    <div class="ClassSousType">
                      <span class="span-title ml-3"
                        >est défini par les Sous-catégries</span
                      >
                      <multiselect
                        class="multiselectM"
                        @input="handleSousTypeChange(sousType)"
                        v-model="sousType.subcategories.data"
                        :options="sousType.listSubCategorie || []"
                        placeholder="Rechercher"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :preserve-search="true"
                        :showLabels="false"
                        label="name"
                        track-by="name"
                        :multiple="true"
                      >
                        <span slot="noResult">Aucune option trouvée.</span>
                        <span slot="noOptions">Aucune option trouvée.</span>
                      </multiselect>
                    </div>
                  </div>

                  <div class="icon-sous-categorie">
                    <b-button
                      size="sm"
                      class="btn-left non-drag-area"
                      @click="tryDeleteSousType(sousType)"
                      title="Supprimer un sous-type "
                    >
                      <font-awesome-icon class icon="times" />
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </Draggable>
        </Container>
      </div>
      <!-- delete sousType -->
      <b-modal
        no-close-on-backdrop
        ref="deleteSousTypeModal"
        id="deleteSousTypeModal"
        title="Supprimer un sous-type"
        :hide-footer="true"
        @hidden="resetModal"
      >
        <p class="mb-3">
          Êtes-vous certain de vouloir supprimer le sous-type
          <strong>
            {{ sousTypeToDelete ? sousTypeToDelete.name : '' }}
          </strong>
          ?
        </p>
        <div class="message">
          <div v-if="getProjectsTypesLoading" class="loading">
            Chargement en cours...
          </div>
          <div v-if="getProjectsTypesError" class="error">
            <ul v-if="Array.isArray(getProjectsTypesError)">
              <li v-for="(e, index) in getProjectsTypesError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getProjectsTypesError }}</span>
          </div>
        </div>
        <div class="form-actions">
          <b-button
            @click="hideModal('deleteSousTypeModal')"
            variant="outline-secondary"
          >
            Annuler
          </b-button>
          <b-button variant="success" @click="deleteSousType">
            Valider
          </b-button>
        </div>
      </b-modal>
      <!-- Add Sous_Type_Projet Modal -->
      <b-modal
        no-close-on-backdrop
        id="addProjetsSousTypesModal"
        ref="addProjetsSousTypesModal"
        title="Ajouter un sous-type"
        :hide-footer="true"
        @show="resetModal"
        @hidden="resetModal"
      >
        <form @submit.prevent="addBlockSousType">
          <b-form-group label="Nom" label-for="Nom-input">
            <b-form-input
              id="Nom-input"
              v-model="SousTypeToAdd.name"
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Catégorie" label-for="categorie-select">
            <multiselect
              class="multiselectS"
              @input="handleCategorieChange($event)"
              v-model="SousTypeToAdd.categories"
              :options="getListCategorieInTable"
              placeholder="Rechercher"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              :showLabels="false"
              label="name"
              track-by="name"
              :multiple="true"
            >
              <span slot="noResult">Aucune option trouvée.</span>
              <span slot="noOptions">Aucune option trouvée.</span>
            </multiselect>
          </b-form-group>
          <b-form-group label="Sous-Catégorie" label-for="categorie-select">
            <multiselect
              class="multiselectS"
              v-model="SousTypeToAdd.sub_categories"
              :options="getListSubCategorie"
              placeholder="Rechercher"
              :close-on-select="true"
              :clear-on-select="true"
              :preserve-search="true"
              :showLabels="false"
              label="name"
              track-by="name"
              :multiple="true"
            >
              <span slot="noResult">Aucune option trouvée.</span>
              <span slot="noOptions">Aucune option trouvée.</span>
            </multiselect>
          </b-form-group>
          <div class="message">
            <div v-if="getProjectsTypesLoading" class="loading">
              Chargement en cours...
            </div>
            <div v-if="getProjectsTypesError" class="error">
              <ul v-if="Array.isArray(getProjectsTypesError)">
                <li v-for="(e, index) in getProjectsTypesError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <span v-else>{{ getProjectsTypesError }}</span>
            </div>
          </div>
          <div class="form-actions">
            <b-button
              @click="hideModal('addProjetsSousTypesModal')"
              variant="outline-secondary"
            >
              Annuler
            </b-button>
            <b-button
              variant="success"
              type="submit"
              :disabled="getProjectsTypesLoading"
            >
              Valider
            </b-button>
          </div>
        </form>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { Container, Draggable } from 'vue-smooth-dnd'
import { mapActions, mapGetters } from 'vuex'

export default {
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      initLoading: true,
      selectedMenu: 'champ-personnalise',
      selectedProjectType: null,
      SousTypeToAdd: {
        name: null,
        sub_categories: [],
        categories: []
      },
      typeName: null,
      customizable: 1,
      upperDropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      dropPlaceholderOptions: {
        animationDuration: '150',
        showOnTop: true
      },
      sousTypeToDelete: null,
      error: null,
      projectToUpdate: null
    }
  },

  methods: {
    ...mapActions([
      'fetchProjetsTypes',
      'fetchProjetsTypessubtypes',
      'addSubType',
      'fetchAllTablesCustomFields',
      'fetchSubCategorieForSubType',
      'fetchSubCategorieForCategorie',
      'fetchListCategorieInTable',
      'updateCategoriesCategorie',
      'deleteSubType',
      'updateSubType',
      'resetErrorProjectType',
      'updateProjectType',
      'fetchCustomFieldProject'
    ]),
    clickMenu(menu) {
      this.selectedMenu = menu
    },
    handleCategorieChange($event, sousType) {
      this.fetchSubCategorieForCategorie({ event: $event, sousType })
    },
    //updated
    async updateTypeProject() {
      if (this.typeName == this.selectedProjectType.name) {
        return
      }

      const data = {}

      if (this.typeName != this.selectedProjectType.name) {
        data.name = this.typeName
      }
      const response = await this.updateCategoriesCategorie({
        project: this.selectedProjectType,
        data: data
      })
      if (response) {
        this.$bvToast.toast('Données envoyées avec succès', {
          title: 'Succès',
          autoHideDelay: 2000,
          toaster: 'b-toaster-bottom-right',
          variant: 'success',
          solid: true
        })
      }
    },
    //updated
    async addBlockSousType() {
      let count = this.selectedProjectType.subType.length
      const sousType = {
        name: this.SousTypeToAdd.name,
        order: count,
        sub_categories: this.SousTypeToAdd.sub_categories.map(item => {
          return item.id
        })
      }
      const response = await this.addSubType({
        type: this.selectedProjectType,
        sousType: sousType
      })
      //scroll when add new stage
      if (response) {
        this.$nextTick(() => {
          this.$refs.cardSceneTypeProject.scrollTop = this.$refs.cardSceneTypeProject.scrollHeight
          this.hideModal('addProjetsSousTypesModal')
        })
      }
    },
    //updated
    updateNameSousType(e, sousType) {
      this.updateSubType({
        type: this.selectedProjectType,
        sousType: sousType,
        data: { name: e.target.value }
      })
    },
    //update
    tryDeleteSousType(sousType) {
      this.sousTypeToDelete = sousType
      this.$refs['deleteSousTypeModal'].show()
    },
    //updated
    async deleteSousType() {
      const response = await this.deleteSubType({
        type: this.selectedProjectType,
        sousType: this.sousTypeToDelete
      })
      if (response) {
        this.hideModal('deleteSousTypeModal')
      }
    },
    hideModal(ref) {
      this.$refs[ref].hide()
      this.resetModal()
    },

    resetModal() {
      this.sousTypeToDelete = null
      this.SousTypeToAdd = {
        name: null,
        sub_categories: [],
        categories: []
      }
      this.resetErrorProjectType()
    },

    onColumnDrop(dropResult) {
      const { removedIndex, addedIndex } = dropResult
      if (removedIndex === addedIndex) {
        return
      }
      this.selectedProjectType.subType = this.applyDrag(
        this.selectedProjectType.subType,
        dropResult
      )
      this.updateSubType({
        type: this.selectedProjectType,
        sousType: this.selectedProjectType.subType[addedIndex],
        data: {
          order: addedIndex
        }
      })
    },
    //updated
    handelCancel() {
      this.$router.push('/setting/projects-types')
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr
      const result = [...arr]
      let itemToAdd = payload
      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }
      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }
      for (let index = 0; index < result.length; index++) {
        result[index].order = index
      }
      return result
    },
    handleSousTypeChange(sousType) {
      this.updateSubType({
        type: this.selectedProjectType,
        sousType: sousType,
        data: {
          sub_categories: sousType.subcategories.data.map(item => {
            return item.id
          })
        }
      })
    },
    async getSubCategorieForSubTypes() {
      for (let i = 0; i < this.selectedProjectType.subType.length; i++) {
        await this.fetchSubCategorieForSubType({
          type: this.selectedProjectType,
          sousType: this.selectedProjectType.subType[i]
        })
      }
    },
    async updateProject() {
      const response = await this.updateProjectType(this.projectToUpdate)
      if (response) {
        this.hideModal('ModalUpdate')
      }
    }
  },
  computed: {
    ...mapGetters([
      'getProjectsTypes',
      'getProjectsTypesLoading',
      'getProjectsTypesError',
      'getProjectSubTypes',
      'getListCategorieInTable',
      'getListSubCategorie',
      'getCustomFieldProject',
      'getCustomFieldsMenu'
    ])
  },
  async mounted() {
    if (!this.getProjectsTypes || !this.getProjectsTypes.length) {
      await this.fetchProjetsTypes()
    }

    const search = this.getProjectsTypes.find(
      projectType => projectType.id == this.$route.params.id
    )
    if (!search) {
      this.$router.push('/setting/projects-types')
    }
    this.selectedProjectType = search
    await this.fetchProjetsTypessubtypes(this.selectedProjectType)
    await this.fetchAllTablesCustomFields(this.customizable)
    let idTable = this.getCustomFieldsMenu.find(item => item.name === 'projets')
    await this.fetchListCategorieInTable({
      idTable,
      include: 'subcategories'
    })
    this.getSubCategorieForSubTypes()

    this.typeName = search.name
    this.projectToUpdate = search
    if (this.projectToUpdate.fields) {
      this.projectToUpdate.selected = this.projectToUpdate.fields.map(c => {
        return c.id
      })
    }
    if (this.getCustomFieldProject.length === 0) {
      this.fetchCustomFieldProject()
    }
    this.initLoading = false
  }
}
</script>
<style lang="scss" scoped>
.project-setting {
  height: 100%;
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
    .titleG {
      display: flex;
      align-items: center;
    }
    .titre {
      margin-left: 10px;
    }
  }
  .blockMenu {
    display: flex;
    margin-top: 15px;
    .menu-item {
      cursor: pointer;
      text-decoration: none;
      color: #26292c;
      padding: 10px;
    }
    .menu-item-active {
      color: #2dabe2;
      border-bottom: 2px solid #2dabe2;
    }
  }
  .lineMenu {
    margin-top: -1px;
    margin-bottom: 1rem;
  }
  .button {
    display: flex;
    justify-content: flex-end;
    .addSousType {
      font-size: 15px;
    }
  }
  .message {
    height: 25px;
  }
  .header-sub-type {
    display: flex;
    justify-content: space-between;
  }
  .select-color {
    margin-right: 5px;
  }
  .fleche {
    padding-bottom: 8px;
    font-size: 22px;
  }
  .card-scene {
    height: calc(100% - 180px);
  }
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    box-shadow: none;
    background-color: transparent;
    color: #7b8785;
    background-color: transparent;
    border-color: transparent;
  }
}
</style>
<style lang="scss" scoped>
.column-drag-handle,
.ss-column-drag-handle {
  position: absolute;
  top: 9px;
  left: 6px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.multiselectS {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
  display: block;
  position: relative;
  width: 100%;
  min-height: 40px;
  text-align: left;
  color: #35495e;
  margin-bottom: 1em;
}

.ss-column-drag-handle {
  top: 5px;
  left: 3px;
}

.input-title-sous-type {
  display: flex;
  justify-content: space-between;
  margin-left: 25px;
  .body-drag {
    display: -webkit-box;
    justify-content: space-between;
    input {
      color: black;
      overflow: visible;
      background-color: transparent;
      border: 0px;
      font-size: 15px;
      text-overflow: ellipsis;
    }
    .custom-select-sm {
      width: 139px;
    }
  }
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.icon-sous-categorie {
  .btn-left {
    color: #495057ab;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
    margin-right: 3px;
  }
  .btn-secondary:focus,
  .btn-secondary.focus {
    color: #495057ab;
    background-color: rgba(255, 255, 255, 0.075);
    border-color: rgba(255, 255, 255, 0.075);
  }
}

.btn-sm,
.btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 0.2rem;
}
.card-column-header {
  position: relative;
  width: 100%;
  padding: 9px;
}
.smooth-dnd-container.horizontal {
  display: flex;
}
.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  position: relative;
  overflow: visible;
  margin-top: 5px;
  border: 1px solid #6c757d52;
  padding: 4px;
  background-color: #b0e6f252;
  width: 100%;
  border-radius: 4px;
}
p {
  margin-top: 0;
  margin-bottom: 0rem;
}
.smooth-dnd-container {
  margin: 3px;
}
.smooth-dnd-container {
  position: relative;
  min-height: 30px;
  min-width: 255px;
}
.smooth-dnd-container.horizontal > .smooth-dnd-draggable-wrapper {
  border: 1px solid #6c757d4d;
  margin-right: 8px;
  background-color: #ebebeb5c;
  border-radius: 4px;
}
.custom-select-sm {
  height: calc(1.5em + 0.5rem + 7px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 12px;
  width: 77px;
}
</style>
<style scoped>
.slide-toggle {
  display: none;
}
.slidemenu {
  border: 0px;
  background-color: transparent;
  overflow: hidden;
}

.slidemenu label {
  width: 25%;
  text-align: center;
  display: block;
  float: left;
  color: #333;
  opacity: 4.2;
}

.slidemenu label:hover {
  cursor: pointer;
  color: #666;
}

.slidemenu label span {
  display: block;
  padding: 10px;
}

.slidemenu label .icon {
  font-size: 20px;
  border: solid 2px #333;
  text-align: center;
  height: 50px;
  width: 50px;
  display: block;
  margin: 0 auto;
  line-height: 50px;
}
</style>
<style lang="scss">
.multiselectM {
  min-height: 29px;
  width: 322px;
  margin-left: 5px;
  .multiselect__select {
    height: 32px;
    right: 1px;
    top: 1px;
    padding: 4px 8px;
  }
  .multiselect__tags {
    min-height: 11px;
    padding: 3px 40px 0 8px;
  }
  .multiselect__placeholder {
    margin-bottom: 3px;
  }
}
</style>
